const addDaysToDate = (date, days) => {
	return date.setDate(date.getDate() + days);
};

const subtractDaysToDate = (date, days) => {
	return date.setDate(date.getDate() - days);
};

const formatDate = (date = new Date()) => {
	return date.toLocaleDateString('en-CA', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit'
	});
};

// const START_DATE = new Date(1984, 3, 29);

// function getCurrentClass (track) {
//   let date = new Date();
//   const classNum = track === 'one' ? 1017 : 339;
//   date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
//   const diff = date - START_DATE;
//   const daydiff = Math.ceil(diff / (1000 * 60 * 60 * 24));
//   return (daydiff % classNum) + 1;
// }

export { addDaysToDate, subtractDaysToDate, formatDate };